import React from 'react';
import { Grid } from '@mui/material';
import { useIntl } from 'react-intl';
import DatePicker from 'components/form/date_picker';
import { Form } from 'components/styles_components/form';
import { Formik } from 'formik';
import { WhiteCard } from 'components/styles_components/card';

const DateForm = ({ initialValues, onSubmit }) => {
  const intl = useIntl();

  return (
    <WhiteCard>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}>
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2} justifyContent='center' alignItems='center'>
              <Grid item xs={6}>
                <DatePicker
                  className='width-100'
                  id='dueDateFrom'
                  label={intl.formatMessage({ id: 'filter.from' })}
                  value={values.dueDateFrom}
                  onChange={(value) => {
                    setFieldValue('dueDateFrom', value);
                    handleSubmit();
                  }}/>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  className='width-100'
                  name='dueDateTo'
                  id='dueDateTo'
                  label={intl.formatMessage({ id: 'filter.to' })}
                  value={values.dueDateTo}
                  onChange={(value) => {
                    setFieldValue('dueDateTo', value);
                    handleSubmit();
                  }}/>
              </Grid>
            </Grid>
          </Form>
        )}
    </Formik>
    </WhiteCard>
  );
};

export default DateForm;
