import styled from 'styled-components';
import theme from 'theme';

export const WhiteCard = styled.div`
  border-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.padding.content};
  background: ${theme.palette.primary.white};
  position: relative;
  margin: ${theme.padding.content} 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &.overdue::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${theme.palette.error.main};
    border-top-left-radius: ${theme.radius.card}; /* Match the border radius */
    border-bottom-left-radius: ${theme.radius.card}; /* Match the border radius */
  }
  &.incoming::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: ${theme.palette.warning.main};
    border-top-left-radius: ${theme.radius.card}; /* Match the border radius */
    border-bottom-left-radius: ${theme.radius.card}; /* Match the border radius */
  }
  &.button {
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.columnBackground.hover};
    }
  }
`;

export const TransparentCard = styled.div`
  border-radius: ${theme.radius.card};
  padding: ${theme.padding.content};
  background: transparent;
`;
