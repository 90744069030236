  import React from 'react';
  import { useQuery } from '@apollo/client';
  import { useParams, useSearchParams } from 'react-router-dom';
  import { ContentContainer } from 'components/styles_components/container';
  import { FormattedMessage } from 'react-intl';
  import { InspectionsLink } from 'utils/link';
  import { useError } from 'contexts/error';
  import { BreadCrumbLink } from 'components/styles_components/link';
  import { BreadCrumbTypography, MainTypography } from 'components/styles_components/typography';
  import BreadcrumbContainer from 'components/breadcrumb_container';
  import Inspections from 'components/inspection/related_inspections_index';
  import ShowQuery from 'queries/inspection/show';
  import ShowItem from 'components/inspection/show_item';
  import Loader from 'components/loader';


  const Inspection = () => {
    const setError = useError();
    const { inspectionId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { data = { inspection: { adminRole: true, inspectionFields: [], relatedInspectionsTotal: 0 } }, loading, refetch } = useQuery(
      ShowQuery,
      {
        variables: { id: inspectionId },
        onError: () => {
          setError('error.inspection.show');
        },
      }
    );
    const page = searchParams.get('page') || 1;
    const onPageChange = (page) => {
      setSearchParams({ page });
    };

    if (loading) { return <Loader />; }

    const relatedInspeciotns = () => {
      if (data.inspection.relatedInspectionsTotal === 0) {
        return null;
      }

      return <>
        <MainTypography variant='h5'><FormattedMessage id='inspection.related'/></MainTypography>
        <Inspections
          key='inspections_content'
          onPageChange={onPageChange}
          parentInspectionState={data.inspection.state}
          id={inspectionId}
          identifier={data.inspection.identifier}
          page={parseInt(page)}/>
      </>;

    };

    return (
      <ContentContainer maxWidth='xl'>
        <BreadcrumbContainer>
          <BreadCrumbLink to={InspectionsLink()}>
            <FormattedMessage id='breadcrumb.inspections.index'/>
          </BreadCrumbLink>
          <BreadCrumbTypography>{data.inspection.name}</BreadCrumbTypography>
        </BreadcrumbContainer>

        <ShowItem inspection={data.inspection} refetch={refetch} />
        {relatedInspeciotns()}
      </ContentContainer>
    );
  };

  export default Inspection;
