import React, { useMemo } from 'react';
import { ChapterTypography } from 'components/styles_components/typography';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';
import { DividerContainer } from 'components/styles_components/container';

const Welcome = ({ user }) => {
  const userName = useMemo(() => {
    if (user.name || user.surname) {
      return `${user.name} ${user.surname}`;
    }
    return user.email;
  }, [user]);

  return (
    <>
    <DividerContainer>
      <Typography variant='h6' gutterBottom>
        <FormattedMessage id='dashboard.welcome' values={{ name: userName }} />
      </Typography>
      </DividerContainer>
      <DividerContainer>
        <FormattedMessage id='dashboard.select_application_workflow' />
      </DividerContainer>
    </>
  );
};

export default Welcome;
