import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { ListItemIcon, ListItemText, Toolbar, IconButton, Box, Menu } from '@mui/material';
import { StyledAppBar, TopMenuItem } from 'components/styles_components/menu';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from 'components/styles_components/link';
import { ProfileLink, LoginLink, HomeLink } from 'utils/link';
import MenuIcon from '@mui/icons-material/Menu';
import Cookies from 'js-cookie';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoWide from 'components/layout/logo_wide';
import Logo from 'components/layout/logo';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import TopMenuTitle from 'components/layout/top_menu_title';

const TopMenu = ({sidebarVisible, setSidebarVisible}) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const logout = () => {
    Cookies.remove('authenticated');
    navigate(LoginLink());
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose} >
        <ButtonLink to={ProfileLink()} onClick={handleClose}>
          <TopMenuItem
            selected={location.pathname === ProfileLink()}>
            <ListItemIcon>
              <SettingsIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText><FormattedMessage id='navbar.profile'/></ListItemText>
          </TopMenuItem>
        </ButtonLink>
        <TopMenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize='small'/>
            </ListItemIcon>
          <ListItemText><FormattedMessage id='navbar.logout'/></ListItemText>
        </TopMenuItem>
      </Menu>
    );
  };

  return (
    <StyledAppBar>
      <Toolbar>
        <Box sx={{ width: sidebarVisible ? theme.width.topMenuLogoContainer : 'auto'}}>
          <ButtonLink to={HomeLink()}>
            {sidebarVisible ? <LogoWide /> : <Logo width={50} />}
          </ButtonLink>
        </Box>
        <IconButton onClick={() => {setSidebarVisible(!sidebarVisible);}} >
          <MenuIcon fontSize='large'/>
        </IconButton>
        <TopMenuTitle />
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          aria-haspopup='true'
          onClick={handleMenu}>
          <AccountCircle fontSize='large'/>
        </IconButton>
        {profileMenu()}
      </Toolbar>
    </StyledAppBar>
  );
};

export default TopMenu;

