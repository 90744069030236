import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InspectorInspectionsLink, ProfileLink } from 'utils/link';
import { TopMenuTitleTypography } from 'components/styles_components/typography';
import { FormattedMessage } from 'react-intl';


const TopMenuTitle = () => {
  const location = useLocation();
  const [title, setTitle] = useState('navbar.title.admin');

  useEffect(() => {
    if (location.pathname.startsWith(InspectorInspectionsLink())) {
      setTitle('navbar.title.inspector');
    } else if (location.pathname === ProfileLink()) {
      setTitle('navbar.title.profile');
    } else {
      setTitle('navbar.title.admin');
    }
  }, [location]);

  return (
    <TopMenuTitleTypography variant='h6'>
      <FormattedMessage id={title}/>
    </TopMenuTitleTypography>
  );
};

export default TopMenuTitle;

