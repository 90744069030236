import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseEmptyInfo from 'components/empty_info';
import CreateInspectionSubjectButton from 'components/inspection_subject/button/create';

const EmptyInfo = ({ visible, placeId }) => {
  if (visible) {
    return (
      <BaseEmptyInfo
        severity='success'
        action={
          <CreateInspectionSubjectButton placeId={placeId} size='small'/>
        }
        visible={true}>
          <FormattedMessage id='empty.inspection_subjects.title'/>
      </BaseEmptyInfo>
    );
  } else {
    return null;
  }
};

export default EmptyInfo;
