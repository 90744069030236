import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentContainer } from 'components/styles_components/container';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import BreadcrumbContainer from 'components/breadcrumb_container';
import Calendar from 'components/inspection/calendar';
import { InspectionsLink } from 'utils/link';
import { BreadCrumbLink } from 'components/styles_components/link';

const Inspections = () => {
  return (
    <ContentContainer maxWidth='xl'>
      <BreadcrumbContainer>
          <BreadCrumbLink to={InspectionsLink()}>
            <FormattedMessage id='breadcrumb.inspections.index'/>
          </BreadCrumbLink>
          <BreadCrumbTypography><FormattedMessage id='breadcrumb.inspections.calendar'/></BreadCrumbTypography>
        </BreadcrumbContainer>
      <Calendar mode='admin' />
    </ContentContainer>
  );
};

export default Inspections;
