import React from 'react';
import { Grid, Typography, Box, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import ShowItemRow from 'components/item/show_item_row';
import Place from 'utils/place';
import EditButton from 'components/place/button/edit';
import DeleteButton from 'components/place/button/delete';
import GoogleLinkButton from 'components/navigation/google_link_button';

const ShowItem = ({ place, refetch }) => {
  return (
    <WhiteCard className={Place(place).htmlClass}>
      <Typography variant='h5'><FormattedMessage id='place'/></Typography>
      <Grid container
        direction='row'
        justifyContent='space-between'
        spacing={2}
        alignItems='center'>
        <ShowItemRow label={<FormattedMessage id='name'/>}>{place.name}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.street'/>}>{place.street}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.zip_code'/>}>{place.zipCode}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='place.city'/>}>{place.city}</ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='inspection.overdue.count'/>}>
          {place.overdueInspectionsTotal}
        </ShowItemRow>
        <ShowItemRow label={<FormattedMessage id='inspection.incoming.count'/>}>
          {place.incomingInspectionsTotal}
        </ShowItemRow>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          display='flex'
          justifyContent='flex-end'>
          <ButtonGroup variant='contained'>
            <EditButton
              place={place}
              refetch={refetch} />
            <GoogleLinkButton
              place={place} />
            <DeleteButton
              place={place} />
          </ButtonGroup>
        </Box>
      </Grid>
    </WhiteCard>
  );
};

export default ShowItem;
