import React from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import { useError } from 'contexts/error';
import EditMutation from 'mutations/template/edit';
import Form from 'components/template/form';

const Edit = ({ handleClose, onSuccess, template }) => {
  const setError = useError();
  const [editMutation, { loading }] = useMutation(EditMutation, {
    onCompleted: onSuccess,
    onError: () => {
      setError('error.template.edit');
    },
  });

  return (
    <>
      <ModalTypography variant='h5' align='center'>
        <FormattedMessage id='header.template.edit'/>
      </ModalTypography>
      <Form
        initialValues={{
          id: template.id,
          name: template.name,
          templateFields: template.templateFields.map((field) => ({
            id: field.id,
            name: field.name,
            order: field.order,
          })),
        }}
        processing={loading}
        onSubmit={(values) => editMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Edit;
