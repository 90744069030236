import React from 'react';
import { TextField, Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { MainTypography } from 'components/styles_components/typography';
import StateSelect from 'components/form/state_select';
import DatePicker from 'components/form/date_picker';
import PredefinedFilters from 'components/inspection/predefined_filters';
import Form from 'components/filter/from';

const FilterForm = ({ initialValues, defaultValues, processing, onSubmit, onClose }) => {
  const intl = useIntl();

  return (
    <Form
      header={<FormattedMessage id='header.inspection.filter'/>}
      processing={processing}
      onClose={onClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      contentRenderer={(formik) => (
        <>
          <TextField
            fullWidth
            autoFocus
            margin='dense'
            id='name'
            name='name'
            label={intl.formatMessage({ id: 'name' })}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name) || undefined}
            helperText={formik.touched.name && formik.errors.name}/>
          <StateSelect
            fullWidth
            margin='dense'
            id='state'
            name='state'
            label={intl.formatMessage({ id: 'inspection.state' })}
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.state && Boolean(formik.errors.state) || undefined}
            helperText={formik.touched.state && formik.errors.state}/>
          <FormattedMessage  className='margin-top-10' id='inspection.due_date'/>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                className='width-100'
                id='dueDateFrom'
                label={intl.formatMessage({ id: 'filter.from' })}
                value={formik.values.dueDateFrom}
                onChange={(value) => formik.setFieldValue('dueDateFrom', value)}/>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                className='width-100'
                name='dueDateTo'
                id='dueDateTo'
                label={intl.formatMessage({ id: 'filter.to' })}
                value={formik.values.dueDateTo}
                onChange={(value) => formik.setFieldValue('dueDateTo', value)}/>
            </Grid>
          </Grid>
          <FormattedMessage className='margin-top-10' id='inspection.done_at'/>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                className='width-100'
                id='doneAtFrom'
                label={intl.formatMessage({ id: 'filter.from' })}
                value={formik.values.doneAtFrom}
                onChange={(value) => formik.setFieldValue('doneAtFrom', value)}/>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                className='width-100'
                name='doneAtTo'
                id='doneAtTo'
                label={intl.formatMessage({ id: 'filter.to' })}
                value={formik.values.doneAtTo}
                onChange={(value) => formik.setFieldValue('doneAtTo', value)}/>
            </Grid>
          </Grid>
          <FormattedMessage className='margin-top-10' id='inspection.canceled_at'/>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                className='width-100'
                id='canceledAtFrom'
                label={intl.formatMessage({ id: 'filter.from' })}
                value={formik.values.canceledAtFrom}
                onChange={(value) => formik.setFieldValue('canceledAtFrom', value)}/>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                className='width-100'
                name='canceledAtTo'
                id='canceledAtTo'
                label={intl.formatMessage({ id: 'filter.to' })}
                value={formik.values.canceledAtTo}
                onChange={(value) => formik.setFieldValue('canceledAtTo', value)}/>
            </Grid>
          </Grid>
        </>
      )}>
        <MainTypography align='center' variant='h5'>
          <FormattedMessage id='filter.predefined'/>
        </MainTypography>
        <PredefinedFilters
          initialValues={defaultValues}
          onClick={(values) => {
            onSubmit(values);
          }}/>
      </Form>
  );
};

export default FilterForm;
