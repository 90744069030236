import { List, ListItem, ListItemButton } from '@mui/material';
import theme from 'theme';
import styled from 'styled-components';

export const IndexListItemButton = styled(ListItemButton)`
  margin-bottom: ${theme.padding.content};
  background-color: ${theme.palette.primary.white};
  border-radius: ${theme.radius.card};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: ${theme.palette.columnBackground.hover};
  }

  &.overdue::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: ${theme.palette.error.main};
    border-top-left-radius: ${theme.radius.card}; /* Match the border radius */
    border-bottom-left-radius: ${theme.radius.card}; /* Match the border radius */
  }
  &.incoming::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    background-color: ${theme.palette.warning.main};
    border-top-left-radius: ${theme.radius.card}; /* Match the border radius */
    border-bottom-left-radius: ${theme.radius.card}; /* Match the border radius */
  }
`;

export const IndexListItem = styled(ListItem)`
  margin-bottom: ${theme.padding.content};
  background-color: ${theme.palette.primary.white};
  border-radius: ${theme.radius.card};
`;

export const FieldsList = styled(List)`
  display: inline-grid;
  li {
    padding: 0;
  }
`;

export const FieldsListItem = styled(ListItem)`
  > span {
    padding-right: ${theme.padding.content};
  }
`;

export const IndexListItemHeader = styled(ListItem)`
`;
