import React, { useState } from 'react';
import { Drawer, Button, Box, Pagination, ButtonGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { FilterProvider, useFilter, initPlaceFilterValues } from 'contexts/filter';
import { DividerContainer } from 'components/styles_components/container';
import { useError } from 'contexts/error';
import { usePaginate } from 'contexts/paginate';
import FilterForm from 'components/place/filter_form';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Loader from 'components/loader';
import FilterTags from 'components/filter/tags';
import EmptyInfo from 'components/empty_info';
import CreateButton from 'components/place/button/create';
import PlacesQuery from 'queries/place/index';
import CheckInfo from 'components/user/plan/check_info';
import Index from 'components/place/index';

const IndexContainer = () => {
  const { page, onPageChange } = usePaginate();
  const setError = useError();
  const { placesFilter, setPlacesFilter } = useFilter();
  const [filterVisible, setFilterVisible] = useState(false);
  const { data = { user: { canAddPlace: true }, places: { places: [], perPage: 10 } }, loading } = useQuery(
    PlacesQuery,
    {
      variables: {
        ...placesFilter,
        page: page
      },
      onError: () => {
        setError('error.place.index');
      }
    }
  );

  return (
    <FilterProvider>
      {filterVisible &&
        <Drawer
          anchor='right'
          variant='persistent'
          open={filterVisible}
          onClose={() => { setFilterVisible(false);} }>
          <FilterForm
            initialValues={placesFilter}
            defaultValues={initPlaceFilterValues}
            onSubmit={(values) => {
              onPageChange(1);
              setPlacesFilter(values);
            }}
            onClose={() => { setFilterVisible(false);} }/>
        </Drawer>
      }
      {loading
        ? <Loader/>
        : <>
          <CheckInfo visible={!data.user.canAddPlace} />
          <Box display='flex' justifyContent='flex-end'>
            <ButtonGroup>
              <CreateButton
                disabled={!data.user.canAddPlace} />
              <Button
                onClick={() => setFilterVisible(!filterVisible)}
                variant='contained'
                color={JSON.stringify(initPlaceFilterValues) === JSON.stringify(placesFilter) ? 'primary' : 'warning'}>
                <FormattedMessage id='button.filter'/> <FilterAltIcon/>
              </Button>
            </ButtonGroup>
          </Box>
          <FilterTags
            filterValues={placesFilter}
            defaultValues={initPlaceFilterValues}
            onFilterChange={setPlacesFilter}/>
          <EmptyInfo
            visible={!loading && data.places.placesTotal === 0}>
            <FormattedMessage id='empty.places.title.short'/>
          </EmptyInfo>
          <Index places={data.places}/>
          <Box display='flex' justifyContent='center'>
          {data.places.placesTotalPages> 1 &&
            <Pagination
              count={data.places.placesTotalPages}
              page={page}
              onChange={(_e, nextPage) => { onPageChange(nextPage); }}/>
          }
          </Box>
        </>
      }
      <DividerContainer/>
    </FilterProvider>
  );
};

export default IndexContainer;
