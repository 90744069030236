import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Stack, TextField, Button } from '@mui/material';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Form } from 'components/styles_components/form';
import { ChapterTypography } from 'components/styles_components/typography';
import { RegisterLink, ForgotPasswordLink } from 'utils/link';
import { StyledLink } from 'components/styles_components/link';
import Cookies from 'js-cookie';
import LoginMutation from 'mutations/user/login';
import PasswordField from 'components/form/password';
import validationSchema from 'validation/user/login';

const LoginForm = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [loginMutation, { loading }] = useMutation(LoginMutation, {
    onCompleted: ({ login }) => {
      const expires = new Date(login.expiresIn * 1000);
      Cookies.set('authenticated', true, { expires: expires });
      navigate(0);
    },
  });

  return (
    <Stack>
      <ChapterTypography variant='h5' align='center'>
        <FormattedMessage id='header.user.login'/>
      </ChapterTypography>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema(intl)}
          onSubmit={(values, { setFieldError }) => {
            loginMutation({ variables: values }).catch(() => {
              setFieldError('password', intl.formatMessage({ id: 'error.user.login' }));
            });
          }}>
          {({ handleSubmit, handleChange, handleBlur, values, touched, errors }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin='dense'
                id='email'
                name='email'
                label={intl.formatMessage({ id: 'user.email' })}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email) || undefined}
                helperText={touched.email && errors.email}/>
              <PasswordField
                fullWidth
                margin='dense'
                id='password'
                name='password'
                label={intl.formatMessage({ id: 'user.password' })}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password) || undefined}
                helperText={touched.password && errors.password}/>
              <Button
                fullWidth
                disabled={loading}
                color='primary'
                variant='contained'
                type='submit'>
                <FormattedMessage id='button.login'/>
              </Button>
              <Grid
                container
                direction='row'
                justifyContent='space-between'>
                <StyledLink to={RegisterLink()}>
                  <FormattedMessage id='button.register'/>
                </StyledLink>
                <StyledLink to={ForgotPasswordLink()}>
                  <FormattedMessage id='button.forgot_password'/>
                </StyledLink>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Stack>
  );
};

export default LoginForm;
