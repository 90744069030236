import { MenuItem, Drawer, AppBar, List, ListItemButton } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const SideMenuDrawer = styled(Drawer)`
  > div {
    position: fixed;
    top: ${theme.height.topMenu};
    width: ${theme.width.sideMenu};
    border-color: ${theme.palette.primary.border};
  }
`;

export const MenuList = styled(List)`
`;

export const SideMenuMainItem = styled(ListItemButton)`
  text-decoration: none;
  color: ${theme.palette.link.sideMenu};
  font-weight: bold;
  &.Mui-selected {
    background: none;
    font-weight: bold;
    color: ${theme.palette.link.menu};
  }
`;

export const SideMenuItem = styled(ListItemButton)`
  text-decoration: none;
  color: ${theme.palette.link.sideMenu};
  margin-top: 10px;
  margin-left: 55px;
  &.Mui-selected {
    background: none;
    font-weight: bold;
    color: ${theme.palette.link.menu};
  }
`;

export const StyledAppBar = styled(AppBar)`
  background-color: ${theme.palette.primary.white};
  border-bottom: 1px solid ${theme.palette.primary.border};
  box-shadow: none;
  position: fixed;
  height: ${theme.height.topMenu};
`;

export const TopMenuItem = styled(MenuItem)`
  text-decoration: none;
  color: ${theme.palette.text.primary};
`;
