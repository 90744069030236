import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PageContainer } from 'components/styles_components/container';
import { MainDiv } from 'components/styles_components/container';
import { Grid } from '@mui/material';
import Cookies from 'js-cookie';
import Footer from 'components/layout/footer';
import Logo from 'components/layout/logo';


const PublicRoute = () => {
  const accessToken = Cookies.get('authenticated');
  if (accessToken) {
    return <Navigate to='/'/>;
  }
  return (
    <>
      <MainDiv>
        <PageContainer maxWidth='xl'>
          <Grid
            container
            direction='row'
            justifyContent='center'>
              <Logo width={250} className='main' />
          </Grid>
          <Outlet/>
        </PageContainer>
      </MainDiv>
      <Footer/>
    </>
  );
};

export default PublicRoute;
