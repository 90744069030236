import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { PlacesLink } from 'utils/link';
import { useError } from 'contexts/error';
import { BreadCrumbLink } from 'components/styles_components/link';
import { ContentContainer } from 'components/styles_components/container';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { PaginateProvider } from 'contexts/paginate';
import PlaceQuery from 'queries/place/show';
import InspectionSubjectsContainer from 'components/inspection_subject/index_container';
import AssignedUsersContainer from 'components/assigned_users/index_container';
import InspectionsContainer from 'components/inspection/index_container';
import ShowItem from 'components/place/show_item';
import Loader from 'components/loader';
import BreadcrumbContainer from 'components/breadcrumb_container';
import TabsContainer from 'components/tabs_container';
import InspectionSubjectsEmptyInfo from 'components/inspection_subject/wizard/empty_info';
import InspectionsEmptyInfo from 'components/inspection/wizard/empty_info';
import PlaceUtils from 'utils/place';

const Place = () => {
  const { id } = useParams();
  const setError = useError();
  const { data = { place: { adminRole: true, overdueInspections: [], incomingInspections: [] } }, loading, refetch } = useQuery(
    PlaceQuery,
    {
      variables: { id },
      onError: () => {
        setError('error.place.show');
      }
    }
  );
  const placeUtils = PlaceUtils(data.place);

  if (loading) { return <Loader />; }

  return (
    <ContentContainer maxWidth='xl'>
      <InspectionSubjectsEmptyInfo
        visible={!loading && !placeUtils.hasInspectionSubjects}
        placeId={id} />
      <InspectionsEmptyInfo
        visible={!loading && placeUtils.hasInspectionSubjects && !placeUtils.hasInspections}
        placeId={id} />
      <BreadcrumbContainer>
        <BreadCrumbLink to={PlacesLink()}>
          <FormattedMessage id='breadcrumb.place.index'/>
        </BreadCrumbLink>
        <BreadCrumbTypography>{data.place.name}</BreadCrumbTypography>
      </BreadcrumbContainer>
      <ShowItem place={data.place} refetch={refetch} />
      <TabsContainer
        tabs={[
          { label: <FormattedMessage id='inspection_subject.count'/>, visible: true },
          { label: <FormattedMessage id='inspection.count'/>, visible: true },
          { label: <FormattedMessage id='place.allowed_users'/>, visible: data.place.ownerRole }
        ]}
        content={[
          <PaginateProvider key='inspection_subjects_content'>
            <InspectionSubjectsContainer
              hasInspectionSubjects={placeUtils.hasInspectionSubjects}
              canAddInspectionSubject={data.place.canAddInspectionSubject}
              placeId={id} />
          </PaginateProvider>,
          <PaginateProvider key='place_inspections_content'>
            <InspectionsContainer
              hasInspections={placeUtils.hasInspectionSubjects && !placeUtils.hasInspections}
              placeId={id} />
          </PaginateProvider>,
          data.place.ownerRole &&
          <AssignedUsersContainer
            key='inspection_subjects_content'
            placeId={id} />
        ].filter(Boolean)}/>
    </ContentContainer>
  );
};

export default Place;
