import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { ContentContainer } from 'components/styles_components/container';
import { useParams } from 'react-router-dom';
import { InspectionSubjectsLink } from 'utils/link';
import { useError } from 'contexts/error';
import { BreadCrumbLink } from 'components/styles_components/link';
import { BreadCrumbTypography } from 'components/styles_components/typography';
import { PaginateProvider } from 'contexts/paginate';
import ShowQuery from 'queries/inspection_subject/show';
import ShowItem from 'components/inspection_subject/show_item';
import Loader from 'components/loader';
import InspectionsContainer from 'components/inspection/index_container';
import BreadcrumbContainer from 'components/breadcrumb_container';
import InspectionsEmptyInfo from 'components/inspection/wizard/empty_info';
import TabsContainer from 'components/tabs_container';
import InspectionSubjectUtils from 'utils/inspection_subject';

const Show = () => {
  const setError = useError();
  const { inspectionSubjectId } = useParams();
  const { data = { inspectionSubject: { adminRole: true, inspectionsTotal: 0, place: {} } }, loading, refetch } = useQuery(
    ShowQuery,
    {
      variables: { id: inspectionSubjectId },
      onError: () => {
        setError('error.inspection_subject.show');
      }
    }
  );
  const inspectionSubjectUtils = InspectionSubjectUtils(data.inspectionSubject);

  if (loading) { return <Loader />; }

  return (
    <ContentContainer maxWidth='xl'>
      <BreadcrumbContainer>
        <BreadCrumbLink to={InspectionSubjectsLink()}>
          <FormattedMessage id='breadcrumb.inspection_subjects.index'/>
        </BreadCrumbLink>
        <BreadCrumbTypography>{data.inspectionSubject.name}</BreadCrumbTypography>
      </BreadcrumbContainer>
      <InspectionsEmptyInfo
        visible={!loading && data.inspectionSubject.inspectionsTotal == 0}
        placeId={data.inspectionSubject.place.id}
        inspectionSubjectId={inspectionSubjectId} />
      <ShowItem inspectionSubject={data.inspectionSubject} refetch={refetch} />
      <TabsContainer
        tabs={[
          { label: <FormattedMessage id='inspection.count'/>, visible: true},
        ]}
        content={[
          <PaginateProvider key='inspections-tab'>
            <InspectionsContainer
              placeId={data.inspectionSubject.place.id}
              inspectionSubjectId={inspectionSubjectId} />
          </PaginateProvider>
        ]} />
    </ContentContainer>
  );
};

export default Show;
