import React from 'react';
import { FormattedMessage } from 'react-intl';
import BaseEmptyInfo from 'components/empty_info';
import CreateInspectionButton from 'components/inspection/button/create';
import AddIcon from '@mui/icons-material/Add';

const EmptyInfo = ({ visible, placeId, inspectionSubjectId }) => {
  if (!visible) { return null; }

  if (visible) {
    return (
      <BaseEmptyInfo
        visible={true}
        severity='success'
        action={
          <CreateInspectionButton
            size='small'
            placeId={placeId}
            inspectionSubjectId={inspectionSubjectId} />}
        >
        <FormattedMessage id='empty.inspections.title'/>
      </BaseEmptyInfo>
    );
  } else {
    return null;
  }
};

export default EmptyInfo;
