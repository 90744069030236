import React from 'react';
import { Grid } from '@mui/material';
import { Footer as StyledFooter } from 'components/styles_components/footer';
import { FormattedMessage } from 'react-intl';
import theme from 'theme';
// TODO
const Footer = ({ sidebarVisible }) => {
  return (
    <StyledFooter>
      <Grid
      sx={sidebarVisible ? { paddingLeft: theme.width.sideMenu } : {}}
        container
        justifyContent='center'
        alignItems='center'>
        <FormattedMessage
          id='footer.title'
          values={{
            year: new Date().getFullYear()
          }}
          />
      </Grid>

    </StyledFooter>
  );
};

export default Footer;

