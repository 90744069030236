import React from 'react';
import { useMutation } from '@apollo/client';
import { useError } from 'contexts/error';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import DoMutation from 'mutations/inspection/do';
import Form from 'components/inspection/modal/execute/execute_form';
import InspectionResult from 'utils/inspection_result';

const Execute = ({ inspection, handleClose, onSuccess }) => {
  const setError = useError();
  const [executeInspection, { loading }] = useMutation(DoMutation, {
    onCompleted: onSuccess,
    onError: () => {
      setError('error.inspection.execute');
    },
  });
  const executeValues = (values) => {
    return {
      ...values,
        inspectionFields: values.inspectionFields.map(field => ({
        id: field.id,
        value: field.value || false
      }))
    };
  };

  return (
    <>
      <ModalTypography variant='h5' align='center'>
        <FormattedMessage id='header.inspection.do'/>
      </ModalTypography>
      <Form
        initialValues={{
          notes: inspection.notes || undefined,
          doneAt: new Date(),
          dueDate: null,
          result: InspectionResult().SUCCESS,
          id: inspection.id,
          inspectionFields: inspection.inspectionFields
        }}
        processing={loading}
        onSubmit={(values) => executeInspection({variables: executeValues(values)})}
        onCancel={handleClose}/>
    </>
  );
};

export default Execute;
