import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FlexDiv } from 'components/styles_components/container';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const Confimation = ({ title, description, onClick, children }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FlexDiv onClick={handleClickOpen}>
        {children}
      </FlexDiv>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}><FormattedMessage id='button.cancel'/></Button>
          <Button onClick={() => {
            onClick();
            handleClose();
          }} autoFocus>
            <FormattedMessage id='button.confirm'/>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Confimation;
