import React from 'react';
import { useFormik } from 'formik';
import { Box, Button, IconButton } from '@mui/material';
import { DrawerForm } from 'components/styles_components/form';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import CloseIcon from '@mui/icons-material/Close';

const Drawer = ({ contentRenderer, header, defaultValues, initialValues, processing, onClose, onSubmit, children }) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    enableReinitialize: true
  });

  return (
    <DrawerForm onSubmit={formik.handleSubmit}>
      <Box display='flex' justifyContent='flex-end'>
        <IconButton
          onClick={onClose}>
          <CloseIcon/>
        </IconButton>
      </Box>
      <ModalTypography variant='h5' align='center'>
        {header}
      </ModalTypography>
      {contentRenderer(formik)}
      <Button
        fullWidth
        className='margin-top-10'
        disabled={!formik.isValid || processing}
        color='primary'
        variant='contained'
        type='submit'>
          <FormattedMessage id='button.filter'/>
      </Button>
      <Box display='flex' justifyContent='flex-end'>
        <Button
          variant='text'
          size='small'
          disabled={processing}
          onClick={() => {
            formik.resetForm();
            formik.setValues(defaultValues);
            onSubmit(defaultValues);
            onClose();
          }}>
          <FormattedMessage id='button.reset'/>
        </Button>
      </Box>
      {children}
    </DrawerForm>
  );
};

export default Drawer;
