import React, { useState } from 'react';
import { Drawer, Button, Pagination, Box, ButtonGroup } from '@mui/material';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { FilterProvider, useFilter, initInspectionSubjectFilterValues } from 'contexts/filter';
import { DividerContainer } from 'components/styles_components/container';
import { useError } from 'contexts/error';
import { usePaginate } from 'contexts/paginate';
import FilterForm from 'components/inspection_subject/filter_form';
import FilterTags from 'components/filter/tags';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Loader from 'components/loader';
import EmptyInfo from 'components/empty_info';
import CreateButton from 'components/inspection_subject/button/create';
import CheckInfo from 'components/user/plan/check_info';
import InspectionSubjectQuery from 'queries/inspection_subject/index';
import Index from 'components/inspection_subject/index';

const IndexContainer = ({ placeId }) => {
  const { page, onPageChange } = usePaginate();
  const { inspectionSubjectsFilter, setInspectionSubjectsFilter } = useFilter();
  const setError = useError();
  const [filterVisible, setFilterVisible] = useState(false);
  const { data = { user: { canAddInspectionSubject: true }, inspectionSubjects: { inspectionSubjects: [] } }, loading } = useQuery(
    InspectionSubjectQuery,
    {
      variables: {
        ...inspectionSubjectsFilter,
        page: page,
        placeId: placeId
      },
      onError: () => {
        setError('error.inspection_subject.index');
      }
    }
  );
  const { canAddInspectionSubject } = data.user;

  return (
    <FilterProvider>
      {filterVisible &&
        <Drawer
          anchor='right'
          open={filterVisible}
          onClose={() => { setFilterVisible(false);} }>
          <FilterForm
            initialValues={inspectionSubjectsFilter}
            defaultValues={initInspectionSubjectFilterValues}
            onSubmit={(values) => {
              onPageChange(1);
              setFilterVisible(false);
              setInspectionSubjectsFilter(values);
            }}
            onClose={() => { setFilterVisible(false);} }/>
        </Drawer>
      }
      { loading
        ? <Loader/>
        : <>
          <CheckInfo visible={!canAddInspectionSubject} />
          <Box display='flex' justifyContent='flex-end'>
            <ButtonGroup>
              <CreateButton
                disabled={!canAddInspectionSubject}
                placeId={placeId}/>
              <Button
                onClick={() => setFilterVisible(!filterVisible)}
                variant='contained'
                color={JSON.stringify(initInspectionSubjectFilterValues) === JSON.stringify(inspectionSubjectsFilter) ? 'primary' : 'warning'}>
                <FormattedMessage id='button.filter'/> <FilterAltIcon/>
              </Button>
            </ButtonGroup>
          </Box>
          <FilterTags
            filterValues={inspectionSubjectsFilter}
            defaultValues={initInspectionSubjectFilterValues}
            onFilterChange={setInspectionSubjectsFilter}/>
          <EmptyInfo
            visible={!loading && data.inspectionSubjects.inspectionSubjects.length === 0}>
            <FormattedMessage id='empty.inspection_subjects.title.short'/>
          </EmptyInfo>
          <Index inspectionSubjects={data.inspectionSubjects} />
          <Box display='flex' justifyContent='center'>
          { data.inspectionSubjects.inspectionSubjectsTotalPages> 1 &&
            <Pagination
              count={data.inspectionSubjects.inspectionSubjectsTotalPages}
              page={page}
              onChange={(_e, nextPage) => { onPageChange(nextPage); }}/>
          }
          </Box>
        </>
      }
      <DividerContainer/>
    </FilterProvider>
  );
};

export default IndexContainer;
