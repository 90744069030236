import styled from 'styled-components';
import theme from 'theme';

export const DayHeader = styled.div`
  border-top-left-radius: ${theme.shape.borderRadius}px;
  border-top-right-radius: ${theme.shape.borderRadius}px;
  padding: ${theme.padding.content};
  background: ${theme.palette.primary.white};

  &.empty {
    border-radius: ${theme.shape.borderRadius}px;
    margin-bottom: ${theme.padding.content};
  }
`;

export const DayContent = styled.div`
  background-color: ${theme.palette.background.secondary};
  padding: ${theme.padding.sectionContainer};
  border-bottom-left-radius: ${theme.radius.card};
  border-bottom-right-radius: ${theme.radius.card};
  margin-bottom: ${theme.padding.content};
`;
