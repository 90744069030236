import React from 'react';
import { InfoAlert } from 'components/styles_components/alert';

const EmptyInfo = ({ visible=false, action=null, icon=null, severity='info', children }) => {
  if (!visible) { return null; }

  return (
    <InfoAlert
      icon={icon}
      variant='outlined'
      action={action}
      severity={severity}>
      {children}
    </InfoAlert>
  );

};

export default EmptyInfo;
