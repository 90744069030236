import React, { useState } from 'react';
import RemindPasswordMutation from 'mutations/user/remind_password';
import { Grid, Stack, TextField, Button, Alert } from '@mui/material';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Form } from 'components/styles_components/form';
import { ChapterTypography } from 'components/styles_components/typography';
import { RegisterLink, LoginLink } from 'utils/link';
import { useError } from 'contexts/error';
import { StyledLink } from 'components/styles_components/link';
import { SectionGrid } from 'components/styles_components/container';
import validationSchema from 'validation/user/forgot_password';

const ForgotPasswordForm = () => {
  const setError = useError();
  const intl = useIntl();
  const [successAlertVisible, setSuccessAlertVisible] = useState(false);
  const [remindPasswordMutation] = useMutation(RemindPasswordMutation, {
    onCompleted: ({ remindPassword }) => {
      if(remindPassword.success) {
        setSuccessAlertVisible(true);
      } else {
        setError('error.user.remind_password');
      }

    },
    onError: () => {
      setError('error.user.remind_password');
    },
  });

  return (
    <Stack>
      <ChapterTypography variant='h5' align='center'>
        <FormattedMessage id='header.user.forgot_password'/>
      </ChapterTypography>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema(intl)}
          onSubmit={(values, { setSubmitting }) => {
            remindPasswordMutation({variables: values})
              .then(() => setSubmitting(false))
              .catch(() => setSubmitting(false));
          }}>
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin='dense'
                id='email'
                name='email'
                label={intl.formatMessage({ id: 'user.email' })}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email) || undefined}
                helperText={touched.email && errors.email}/>
              <Button
                fullWidth
                disabled={isSubmitting}
                color='primary'
                variant='contained'
                type='submit'>
                <FormattedMessage id='button.remind_password'/>
              </Button>
              <Grid
                container
                direction='row'
                justifyContent='space-between'>
                <StyledLink to={RegisterLink()}>
                  <FormattedMessage id='button.register'/>
                </StyledLink>
                <StyledLink to={LoginLink()}>
                  <FormattedMessage id='button.login'/>
                </StyledLink>
              </Grid>
            </Form>
          )}
        </Formik>
        {successAlertVisible &&
          <SectionGrid>
            <Alert
              variant='outlined'
              onClose={() => setSuccessAlertVisible(false)}
              severity='success'>
              <FormattedMessage id='info.user.remind_password.success'/>
            </Alert>
          </SectionGrid>
      }
      </Grid>
    </Stack>
  );
};

export default ForgotPasswordForm;
