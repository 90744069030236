import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { FormattedMessage } from 'react-intl';
import Plan from 'components/user/profile/modal/plan';

const PlanSelectButton = ({ refetch }) => {
  const {addModal, removeModal} = useModal();

  return (
    <Button
      variant='contained'
      onClick={() => (addModal(
        <Plan
          onSuccess={(data) => {
            if (data.selectPlan.url) {
              window.location.href = data.selectPlan.url;
            } else {
              refetch();
              removeModal();
            }
          }}
          handleClose={() => removeModal()} />
      ))}>
      <FormattedMessage id='button.change_plan'/>
    </Button>

  );
};

export default PlanSelectButton;
