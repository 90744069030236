import { Grid, Box, Container } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const ContainerBox = styled.div`
  padding: ${theme.padding.content};
`;

export const PageContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  order: 2;
  max-width: 100%;
  padding-bottom: ${theme.height.footer};
`;

export const ContentContainer = styled(Container)`
  padding-top: ${theme.padding.container};
`;

export const SectionGrid = styled(Grid)`
  padding-top: ${theme.padding.sectionContainer};
`;

export const TagGrid = styled(Grid)`
  padding: ${theme.padding.content};
`;

export const CeneteredBox = styled(Box)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DividerContainer = styled(Box)`
  padding: ${theme.padding.section};
`;

export const MainDiv = styled.div`
  display: flex;
  flex: 1;
`;

export const Div = styled.div`
  padding: ${theme.padding.small} 0;
`;

export const FlexDiv = styled.div`
  display: flex;
`;
