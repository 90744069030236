import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Stack, TextField, Button } from '@mui/material';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Form } from 'components/styles_components/form';
import { ChapterTypography } from 'components/styles_components/typography';
import { RegisterLink, ForgotPasswordLink } from 'utils/link';
import { StyledLink } from 'components/styles_components/link';
import Cookies from 'js-cookie';
import ResetMutation from 'mutations/user/reset_password';
import PasswordField from 'components/form/password';
import validationSchema from 'validation/user/reset_password';

const ResetForm = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { token } = useParams();

  const [resetMutation, { loading }] = useMutation(ResetMutation, {
    onCompleted: ({ resetPassword }) => {
      Cookies.set('authenticated', true, { expires: resetPassword.expiresIn });
      navigate(0);
    },
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        token: token,
      }}
      validationSchema={validationSchema(intl)}
      onSubmit={(values, { setFieldError }) => {
        resetMutation({ variables: values }).catch(() => {
          setFieldError('password', intl.formatMessage({ id: 'error.user.reset_password' }));
        });
      }}>
      {formik => (
        <Stack>
          <ChapterTypography variant='h5' align='center'>
            <FormattedMessage id='header.user.reset_password'/>
          </ChapterTypography>
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'>
            <Form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                margin='dense'
                id='email'
                name='email'
                label={intl.formatMessage({ id: 'user.email' })}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email) || undefined}
                helperText={formik.touched.email && formik.errors.email}/>
              <PasswordField
                fullWidth
                margin='dense'
                id='password'
                name='password'
                label={intl.formatMessage({ id: 'user.password' })}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password) || undefined}
                helperText={formik.touched.password && formik.errors.password}/>
              <Button
                fullWidth
                disabled={loading}
                color='primary'
                variant='contained'
                type='submit'>
                <FormattedMessage id='button.reset'/>
              </Button>
              <Grid
                container
                direction='row'
                justifyContent='space-between'>
                <StyledLink to={RegisterLink()}>
                  <FormattedMessage id='button.register'/>
                </StyledLink>
                <StyledLink to={ForgotPasswordLink()}>
                  <FormattedMessage id='button.forgot_password'/>
                </StyledLink>
              </Grid>
            </Form>
          </Grid>
        </Stack>
      )}
    </Formik>
  );
};

export default ResetForm;
