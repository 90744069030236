import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { List, ListItemText, useMediaQuery, ListItemIcon, Collapse } from '@mui/material';
import { SideMenuDrawer, MenuList, SideMenuItem, SideMenuMainItem } from 'components/styles_components/menu';
import { FormattedMessage } from 'react-intl';
import { InspectionsLink, InspectionSubjectsLink, InspectorInspectionsLink, PlacesLink, TemplatesLink, UsersLink, InspectorInspectionsCalendarLink, InspectionsCalendarLink } from 'utils/link';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const SideMenu = ({visible, hideMenu}) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onItemClick = () => {
    if (isMobile) {
      hideMenu()
    }
  }

  const inspectorItems = () => {
    let items = {};
    items[InspectorInspectionsCalendarLink()] = 'navbar.inspections_calendar';
    items[InspectorInspectionsLink()] = 'navbar.inspector_inspections';
    return items;
  }

  const adminItems = () => {
    let items = {};
    items[InspectionsCalendarLink()] = 'navbar.inspections_calendar';
    items[PlacesLink()] = 'navbar.places';
    items[InspectionSubjectsLink()] = 'navbar.inspection_subjects';
    items[InspectionsLink()] = 'navbar.inspections';
    items[TemplatesLink()] = 'navbar.templates';
    items[UsersLink()] = 'navbar.users';
    return items;
  }

  const inspectorList = () => {
    let items = inspectorItems();
    return Object.keys(items).map((link) => {
      return item(link, items[link]);
    });
  }

  const adminList = () => {
    let items = adminItems();
    return Object.keys(items).map((link) => {
      return item(link, items[link]);
    });
  }

  const item = (link, translationId,) => {
    return (
      <SideMenuItem key={link} to={link} onClick={onItemClick}
        selected={location.pathname === link}>
        <ListItemText><FormattedMessage id={translationId}/></ListItemText>
      </SideMenuItem>
    );
  };

  return (
    <SideMenuDrawer
      variant='persistent'
      open={visible}>
      <List>
        <SideMenuMainItem
          selected={Object.keys(inspectorItems()).includes(location.pathname)}>
            <ListItemIcon>
              <EngineeringIcon />
            </ListItemIcon>
            <ListItemText><FormattedMessage id='navbar.inspector.view' /></ListItemText>
        </SideMenuMainItem>
        <MenuList >
          {inspectorList()}
        </MenuList>
        <SideMenuMainItem
          selected={Object.keys(adminItems()).includes(location.pathname)}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText><FormattedMessage id='navbar.admin.view' /></ListItemText>
        </SideMenuMainItem>
        <MenuList>
          {adminList()}
        </MenuList>
      </List>
    </SideMenuDrawer>
  );
};

export default SideMenu;

