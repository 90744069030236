import React from 'react';
import { Grid, Icon, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { WhiteCard } from 'components/styles_components/card';
import { SectionGrid } from 'components/styles_components/container';
import { CardOptionTypography, CardOptionDescriptionTypography } from 'components/styles_components/typography'

const OptionCard = ({ mode = 'admin', descriptionItems, icon, onClick }) => (
  <Grid item xs={12} md={6}>
    <WhiteCard className='button full-height' onClick={onClick}>
      <Grid container>
        <Grid item xs={12}>
          <CardOptionTypography align='center'>
            <FormattedMessage id={mode} />
          </CardOptionTypography>
        </Grid>
        <Grid item xs={12} textAlign='center'>
          <Icon sx={{ fontSize: 128 }}>
            {icon}
          </Icon>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <SectionGrid>
            <CardOptionDescriptionTypography>
              <FormattedMessage id={`dashboard.select_application_workflow.description.${mode}`} />
            </CardOptionDescriptionTypography>
          </SectionGrid>
        </Grid>
        <Grid item xs={12}>
          {Array.from({ length: descriptionItems }, (_, index) => (
            <SectionGrid key={index}>
              <Typography className='padding-right-2'>
                <FormattedMessage id={`dashboard.select_application_workflow.${mode}.${index}.title`} />
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                <FormattedMessage id={`dashboard.select_application_workflow.${mode}.${index}.description`} />
              </Typography>
            </SectionGrid>
          ))}
        </Grid>
      </Grid>
    </WhiteCard>
  </Grid>
);

export default OptionCard;
