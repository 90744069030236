import React from 'react';
import { Box, CardActionArea, Grid, Icon, List, ListItem, Typography } from '@mui/material';
import { useError } from 'contexts/error';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ContentContainer, SectionGrid } from 'components/styles_components/container';
import DashboardQuery from 'queries/user/dashboard';
import Loader from 'components/loader';
import CheckInfo from 'components/user/plan/check_info';
import CreateButton from 'components/inspection/button/create';
import PlaceWizardEmptyInfo from 'components/place/wizard/empty_info';
import InspectionSubjectsWizardEmptyInfo from 'components/inspection_subject/wizard/empty_info';
import InspectionsWizardEmptyInfo from 'components/inspection/wizard/empty_info';
import Calendar from 'components/inspection/calendar';
import Welcome from 'components/dashboard/welcome';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import OptionCard from 'components/dashboard/option_card';
import { InspectionsCalendarLink, InspectorInspectionsCalendarLink } from 'utils/link';

const Place = () => {
  const setError = useError();
  const navigate = useNavigate();
  const { data = { user: { }, inspections: {}, places: {}, inspectionSubjects: {} }, loading } = useQuery(
    DashboardQuery,
    {
      onError: () => {
        setError('error.user.dashboard');
      }
    }
  );

  if (loading) { return <Loader />; }

  return (
    <ContentContainer maxWidth='xl'>
      <Welcome user={data.user} />
      <Grid container spacing={2} alignItems='stretch'>
        <OptionCard
          mode='admin'
          descriptionItems={5}
          onClick={() => navigate(InspectionsCalendarLink())}
          icon={<ManageAccountsIcon fontSize='inherit' />} />
        <OptionCard
          mode='inspector'
          descriptionItems={3}
          onClick={() => navigate(InspectorInspectionsCalendarLink())}
          icon={<EngineeringIcon fontSize='inherit' />} />
      </Grid>

      {/* <CheckInfo visible={!data.user.canAddInspection} />
      {!loading && data.inspections.inspectionsTotal > 0 &&
        <Box
          display='flex'
          justifyContent='flex-end'>
          <CreateButton
            label={<FormattedMessage id='button.add.inspection'/>}
            disabled={!data.user.canAddInspection}/>
        </Box>
      }

      <SectionGrid>
        <PlaceWizardEmptyInfo
          visible={!loading && data.places.placesTotal === 0 } />
        <InspectionSubjectsWizardEmptyInfo
          visible={!loading && data.places.placesTotal > 0 && data.inspectionSubjects.inspectionSubjectsTotal === 0} />
        <InspectionsWizardEmptyInfo
          visible={!loading && data.inspectionSubjects.inspectionSubjectsTotal > 0 && data.inspections.inspectionsTotal == 0} />
      </SectionGrid>

       */}
    </ContentContainer>
  );
};

export default Place;
