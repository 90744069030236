import { Typography } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const MainTypography = styled(Typography)`
  margin-top: ${theme.padding.containerBig};
`;

export const TopMenuTitleTypography = styled(Typography)`
  color: ${theme.palette.link.sideMenu};
`;

export const ChapterTypography = styled(Typography)`
  margin: 50px;
  font-weight: 600;
  color: ${theme.palette.primary.main};
  text-transform: uppercase;
`;

export const CardOptionTypography = styled(Typography)`
  font-weight: 600;
  text-transform: uppercase;
`;

export const CardOptionDescriptionTypography = styled(Typography)`
  margin: 0 #{theme.padding.content};
`;

export const ModalTypography = styled(Typography)`
  margin-bottom: 10px;
`;

export const TitleTypography = styled(Typography)`
  margin: ${theme.padding.section} 0;
`;

export const BreadCrumbTypography = styled(Typography)`
  font-size: ${theme.typography.fontSize}px;
  margin-top: 3px
`;

export const FormHintTypography = styled(Typography)`
  font-size: ${theme.typography.fontSizeSmall}px;
  color: ${theme.palette.text.secondary};
  padding-bottom: ${theme.padding.content};
`;
