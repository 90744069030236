import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'contexts/modal';
import { useInfo } from 'contexts/info';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PlaceLink } from 'utils/link';
import Create from 'components/place/modal/create';
import AddIcon from '@mui/icons-material/Add';

const CreateButton = ({ disabled, size='medium' }) => {
  const {addModal, removeModal} = useModal();
  const setInfo = useInfo();
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Button
      disabled={disabled}
      size={size}
      onClick={() => addModal(
        <Create
          handleClose={() => removeModal()}
          onSuccess={(place) => {
            removeModal();
            setInfo(intl.formatMessage({ id: 'info.place.created' }));
            navigate(PlaceLink(place.id));
          }}/>
      )}
      variant='contained'
      color='success'>
      <FormattedMessage id='button.add'/> <AddIcon/>
    </Button>
  );
};

export default CreateButton;
