
import theme from 'theme';
import styled from 'styled-components';
import { Tabs } from '@mui/material';

export const MainTabs = styled(Tabs)`
  background-color: ${theme.palette.primary.white};
  border-top-left-radius: ${theme.radius.card};
  border-top-right-radius: ${theme.radius.card};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const MainTabsContent = styled.div`
  background-color: ${theme.palette.background.secondary};
  padding: ${theme.padding.sectionContainer};
  border-bottom-left-radius: ${theme.radius.card};
  border-bottom-right-radius: ${theme.radius.card};
`;
