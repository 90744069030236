import '@fontsource/roboto';
import '@fontsource/poppins';
import '@fontsource-variable/outfit';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Outfit',
      'Roboto',
      'sans-serif',
    ].join(','),
    fontSize: 16,
    fontSizeSmall: 14,
    lineHeight: 1.6,
  },
  palette: {
    primary: {
      main: '#3D606F',
      white: '#FFFFFF',
      brown: '#C9B08B',
      border: '#efefef'
    },
    secondary: {
      main: '#8E8F85',
    },
    info: {
      main: '#9ec4de',
      light: '#479bd8',
    },
    background: {
      default: '#f4f6fa',

      secondary: ' #e7ebed',
    },
    columnBackground: {
      main: '#F9F8F9',
      hover: '#ebebeb'
    },
    link: {
      main: '#3D606F',
      menu: '#4d87a0',
      hover: '#98B9D3',
      sideMenu: '#7e7e7e',
    },
  },
  padding: {
    small: '5px',
    content: '10px',
    sectionContainer: '20px',
    container: '100px',
    containerBig: '50px',
    section: '20px',
    footer: '20px'

  },
  radius: {
    card: '8px',
  },
  height: {
    topMenu: '80px',
    footer: '64px',
  },
  width: {
    sideMenu: '380px',
    topMenuLogoContainer: '350px',
    filterDrawer: '450px',
    form: '700px'
  },
  shape: {
    borderRadius: 12, // Default border radius for all components
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          "&.MuiContainer-maxWidthXl": {
            maxWidth: "2100px",
          },
        },
      },
    },
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: '8px', // Custom border radius for buttons
  //       },
  //     },
  //   },
  // },
});


export default theme;
