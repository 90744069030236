import { Box } from '@mui/material';
import styled from 'styled-components';
import theme from 'theme';

export const LogoBox = styled(Box)`
  text-align: center;
  vertical-align: middle;
  margin-top: 15px;

  img {
    display: inline-block;
  }

  &.menu {
    &:hover {
      cursor: pointer;

      img {
        opacity: 0.7
      }
    }
  }

  &.wide {
    margin-top: 0;
    p {
      display: inline-block;
      text-decoration: none;
      font-size: 45px;
      color: ${theme.palette.link.main};
    }
  }

  &.main {
    margin-top: 50px;
  }
`;
