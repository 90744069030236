import React from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { ModalTypography } from 'components/styles_components/typography';
import { useError } from 'contexts/error';
import EditMutation from 'mutations/user/edit';
import Form from 'components/user/profile/modal/edit/form';

const Show = ({ handleClose, onSuccess, user }) => {
  const setError = useError();
  const [editMutation, { loading }] = useMutation(EditMutation, {
    onCompleted: onSuccess,
    onError: () => {
      setError('error.user.profile.edit');
    },
  });

  return (
    <>
      <ModalTypography variant='h5' align='center'>
        <FormattedMessage id='header.user.edit'/>
      </ModalTypography>
      <Form
        initialValues={{
          name: user.name || '',
          surname: user.surname || '',
          sendEmailNotificationsSetting: user.sendEmailNotificationsSetting || false,
        }}
        processing={loading}
        onSubmit={(values) => editMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default Show;
