import React from 'react';
import { useError } from 'contexts/error';
import { Grid, Stack, TextField, Button, Alert } from '@mui/material';
import { Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';
import { Form } from 'components/styles_components/form';
import { ChapterTypography } from 'components/styles_components/typography';
import { ForgotPasswordLink, LoginLink } from 'utils/link';
import { StyledLink } from 'components/styles_components/link';
import { SectionGrid } from 'components/styles_components/container';
import RegisterMutation from 'mutations/user/create';
import PasswordField from 'components/form/password';
import validationSchema from 'validation/user/register';

const Register = () => {
  const [showSuccess, setShowSuccess] = React.useState(false);
  const setError = useError();
  const intl = useIntl();
  const [registerMutation, { loading }] = useMutation(RegisterMutation, {
    onCompleted: () => {
      setShowSuccess(true);
    },
    onError: () => {
      setError('error.user.register');
    },
  });

  return (
    <Stack>
      <ChapterTypography variant='h5' align='center'>
        <FormattedMessage id='header.user.register'/>
      </ChapterTypography>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema(intl)}
          onSubmit={(values) => registerMutation({variables: values})}>
          {({ errors, touched, handleSubmit, values, handleChange, handleBlur }) => (
            <Form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                margin='dense'
                id='email'
                name='email'
                label={intl.formatMessage({ id: 'user.email' })}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email) || undefined}
                helperText={touched.email && errors.email}/>
              <PasswordField
                fullWidth
                margin='dense'
                id='password'
                name='password'
                label={intl.formatMessage({ id: 'user.password' })}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password) || undefined}
                helperText={touched.password && errors.password}/>
              <Button
                fullWidth
                disabled={loading}
                color='primary'
                variant='contained'
                type='submit'>
                <FormattedMessage id='button.register'/>
              </Button>
              <Grid
                container
                direction='row'
                justifyContent='space-between'>
                <StyledLink to={LoginLink()}>
                  <FormattedMessage id='button.login'/>
                </StyledLink>
                <StyledLink to={ForgotPasswordLink()}>
                  <FormattedMessage id='button.forgot_password'/>
                </StyledLink>
              </Grid>
              {showSuccess &&
                <SectionGrid>
                  <Alert
                    severity='success'
                    variant='outlined'>
                    <FormattedMessage id='info.user.register.success'/>
                  </Alert>
                </SectionGrid>
              }
            </Form>
          )}
        </Formik>
      </Grid>
    </Stack>
  );
};

export default Register;
