 import React from 'react';
import { useMutation } from '@apollo/client';
import { ModalTypography } from 'components/styles_components/typography';
import { FormattedMessage } from 'react-intl';
import { useError } from 'contexts/error';
import CreateMutation from 'mutations/inspection_subject/create';
import Form from 'components/inspection_subject/form';

const CreateModal = ({ placeId, handleClose, onSuccess, name = '' }) => {
  const setError = useError();
  const [createMutation, { loading }] = useMutation(CreateMutation, {
    onCompleted: ({ createInspectionSubject }) => {
      onSuccess(createInspectionSubject.inspectionSubject);
    },
    onError: () => {
      setError('error.inspection_subject.create');
    },
  });

  return (
    <>
      <ModalTypography variant='h5' align='center'>
        <FormattedMessage id='header.inspection_subject.create'/>
      </ModalTypography>
      <Form
        initialValues={{
          name: name,
          serialNumber: '',
          placeId: placeId,
        }}
        processing={loading}
        onSubmit={(values) => createMutation({variables: values})}
        onCancel={handleClose}/>
    </>
  );
};

export default CreateModal;
