import React from 'react';
import { LogoBox } from 'components/styles_components/box';
import logo from 'assets/images/logo.png';

const LogoWide = () => {

  return (
    <LogoBox className='wide'>
      <p>Probe</p><img src={logo} alt='Logo' width={38}/><p>List</p>
    </LogoBox>
  );
};

export default LogoWide;

